<script>
    import { createEventDispatcher } from "svelte";

    export let icon = "";
    export let text = "";
    export let classes = "btn bg-gradient-primary";
    export let requesting_text = 'Please wait...'
    export let is_making_api_request = false;

    const dispatch = createEventDispatcher();
    const handleClick = () => {
        dispatch("makeAPIRequest");
    };
</script>

{#if !is_making_api_request}
    <button type="button" class={classes} on:click={handleClick}>
        <div class="d-flex flew-row justify-content-center align-items-center">
            {#if icon}
            <i class={icon}></i>&nbsp; &nbsp;
            {/if}
            <span class="align-self-center mx-1">{text}</span>
        </div>
    </button>
{:else}
    <button class={classes} type="button" disabled>
        <div class="d-flex flew-row justify-content-center align-items-center">
            <span class="spinner-border spinner-border-sm" aria-hidden="true" style="width: 1.2rem; height: 1.2rem;"
            ></span>
            <span class="align-self-center mx-1" role="status">&nbsp;&nbsp;{requesting_text}</span>
        </div>
    </button>
{/if}
