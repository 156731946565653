class Requests {
    get = (url) => {
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: url,
                type: 'GET',
                success: (data) => {
                    resolve(data)
                },
                error: (err) => {
                    reject(err)
                }
            })
        })
    }

    post = (url, data) => {
        const csrfToken = jQuery("input[name='csrfmiddlewaretoken']").val()
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: url,
                type: 'POST',
                data: data,
                headers: {
                    'X-CSRFToken': csrfToken
                },
                processData: false,
                contentType: false,
                cache: false,
                success: (data) => {
                    resolve(data)
                },
                error: (err) => {
                    reject(err)
                }
            })
        })
    }

    patch = (url, data) => {
        const csrfToken = jQuery("input[name='csrfmiddlewaretoken']").val()
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: url,
                type: 'PATCH',
                data: data,
                headers: {
                    'X-CSRFToken': csrfToken
                },
                processData: false,
                contentType: false,
                cache: false,
                success: (data) => {
                    resolve(data)
                },
                error: (err) => {
                    reject(err)
                }
            })
        })
    }

    delete = (url) => {
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: url,
                type: 'DELETE',
                headers: {
                    'X-CSRFToken': jQuery("input[name='csrfmiddlewaretoken']").val()
                },
                success: (data) => {
                    resolve(data)
                },
                error: (err) => {
                    reject(err)
                }
            })
        })
    }
}

export default Requests
