<script>
    let show_password = false;

    export let id = "password";
    export let label = "Password";
    export let password;
    export let validity_class;
    export let invalid_feedback = "Please enter a password.";
</script>

<div class="d-flex justify-content-between">
    <label for="password">{@html label}</label>
    <div class="form-check form-check-reverse">
        <label class="custom-control-label" for="sh-password"
            >Show password</label
        >
        <input
            bind:checked={show_password}
            class="form-check-input"
            type="checkbox"
            id="sh-password"
        />
    </div>
</div>
<div class="mb-3">
    {#if show_password}
        <input
            bind:value={password}
            {id}
            name={id}
            type="text"
            class="form-control {validity_class}"
            placeholder="Password"
            required
        />
    {:else}
        <input
            bind:value={password}
            {id}
            name={id}
            type="password"
            class="form-control {validity_class}"
            placeholder="Password"
            required
        />
    {/if}
    <div class="invalid-feedback">{invalid_feedback}</div>
</div>
