<script>
    import { onMount } from "svelte";

    export let id;
    export let bg;
    export let msg;
    export let show;

    let toast_element;
    let toast;

    onMount(() => {
        jQuery(".toast-container").append(toast_element);

        toast = bootstrap.Toast.getOrCreateInstance(toast_element);
        toast_element.addEventListener("hidden.bs.toast", () => {
            show = false;
        });
    });

    $: show ? setTimeout(() => toast.hide(), 5000) : null;
</script>

<div
    {id}
    class="toast align-items-center {bg} {show ? 'show' : ''}"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    bind:this={toast_element}
>
    <div class="d-flex">
        <div class="toast-body text-white fw-bold">
            {msg}
        </div>
        <button
            type="button"
            class="btn-close text-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
            on:click={() => toast.hide()}
        ></button>
    </div>
</div>
